import React from 'react';
import Chart from 'react-google-charts';
import './App.css';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = { estimates: [] };
  }

  mean() {
    if (this.isEmpty()) return null;
    const sum = this.state.estimates.reduce((a, b) => a + b);
    return sum / this.state.estimates.length;
  }

  max() {
    if (this.isEmpty()) return null;
    return this.state.estimates.reduce((a, b) => Math.max(a, b))
  }

  min() {
    if (this.isEmpty()) return null;
    return this.state.estimates.reduce((a, b) => Math.min(a, b))
  }

  isEmpty() {
    return this.state.estimates.length === 0;
  }

  chartData() {
    const freq = new Map();
    for(let i = this.min(); i <= this.max(); i++) {
      freq.set(i, 0);
    }
    this.state.estimates.forEach((estimate) => {
      freq.set(estimate, freq.get(estimate) + 1);
    });

    const unsortedPoints = Array.from(freq.entries()).map(([estimate, count]) => [estimate, count]);
    const sortedPoints = unsortedPoints.sort((a, b) => a[0] < b[0] ? -1 : 1);
    const points = sortedPoints.map(([estimate, count]) => [String(estimate), count]);
    const res = [['Estimate', 'Count'], ...points];
    return res;
  }

  handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      const num = Number(e.target.value);
      e.target.value = '';
      this.setState({ estimates: [...this.state.estimates, num] });
    }
  };

  handleClearClick = (e) => {
    this.setState({ estimates: [] });
  };

  handleEstimateClick = (e) => {
    const index = Number(e.target.dataset.index);
    this.setState({ estimates: [
      ...this.state.estimates.slice(0, index),
      ...this.state.estimates.slice(index + 1)]
    });
  };

  render() {
    return (
      <div className="App">
        <div className="Stats">
          <p>Min: {this.min() || 'n/a'}</p>
          <p>Mean: <em>{this.isEmpty() ? 'n/a' : this.mean().toFixed(1)}</em></p>
          <p>Max: {this.max() || 'n/a'}</p>
        </div>
        <Chart
          width="100%"
          height={400}
          chartType="ColumnChart"
          loader={<div>Loading Chart</div>}
          data={this.chartData()}
          options={{
            chartArea: { width: '80%' },
            bar: {groupWidth: "95%"},
            legend: { position: "none" },
            animation: {
              duration: 500,
              easing: 'out',
              startup: true,
            },
            hAxis: {
              title: 'Estimate',
              minValue: 0,
            },
            vAxis: {
              title: 'Count',
            },
          }}
          legendToggle
        />
        <div className="Estimates">
          <div className="Heading">Estimates:</div>
          <div className="List">
            <input type="text" onKeyDown={this.handleKeyDown}></input>
            {this.state.estimates.map((estimate, i) => (<span key={i} data-index={i} onClick={this.handleEstimateClick}>{estimate}</span>))}
            <a className="btn" onClick={this.handleClearClick}>Clear All</a>
          </div>
        </div>
      </div>
    );
  }
}

export default App;
